cookieBanner = () => {

    return {
        show: true,
        cookieName: 'ib3ck',
        setCookie(days = 3 * 356) {
            const d = new Date();
            d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
            let expires = "expires="+d.toUTCString();
            document.cookie = this.cookieName + "=" + !this.show + ";" + expires + ";path=/";

            this.show = false;
        },
        getCookie() {
            const matches = document.cookie.match(new RegExp(
              "(?:^|; )" + this.cookieName.replace( /([\.$?*|{}\(\)\[\]\\/\+^])/g, '\\' ) + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : 'true';
        },
        checkCookie() {
            return this.show = (this.getCookie() === "true");
        }
    }
}
