import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import Alpine from 'alpinejs'

import './styles/app.css';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://c21d2bbb590f4727ac1ecd7c69feaf7e@o999783.ingest.sentry.io/5958980",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

import './js/app.js';
import './js/metrics.js';
import './js/turnstile.js';
//import './js/faq.js';
//import './js/image.js';

window.Alpine = Alpine;
import './js/order.js';
import './js/checkout.js';
import './js/article.js';
import './js/feedback.js';
import './js/moneyMove.js';
import './js/cookie.js';
import './js/tabs.js';
Alpine.start();
