feedbackForm = function(urlSend) {
    return {
        urlSend,
        isSubmitting: false,
        isTurnstileReady: false,
        wasSent: false,
        subject: 'common',
        email: '',
        emailError: '',
        message: '',
        messageError: '',
        privacyPolicy: false,
        privacyPolicyError: '',
        error: '',
        onEmailKeyPress() { this.emailError = ''; },
        onPrivacyPolicyClick() { this.privacyPolicyError = ''; },
        init() {
            global.onTurnstileReadyHandler(() => this.isTurnstileReady = true);
        },
        send() {
            this.emailError = this.email ? '' :
                'Введите адрес электроной почты';
            this.messageError = this.message ? '' :
                'Введите текст сообщения';
            this.privacyPolicyError = this.privacyPolicy ? '' :
                'Необходимо согласие на обработку персональных данных';
            if (this.emailError || this.messageError || this.privacyPolicyError) {
                return;
            }

            fetch(this.urlSend, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: this.email,
                    subject: this.subject,
                    message: this.message,
                    token: global.turnstileToken,
                }),
            }).then(x => x.json()).then(x => {
                if (x.error) {
                    this.error = x.error;
                } else {
                    this.wasSent = true;
                }
            }).finally(() => {
                this.isTurnstileReady = false;
                global.onloadTurnstileCallback();
                this.isSubmitting = false;
            })
        },
    }
}
