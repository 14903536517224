article = function () {
    return {
        expandedQuestions: [],
        toggleQuestion(n) {
            this.expandedQuestions[n] = !this.expandedQuestions[n];
        },
        isQuestionExpanded(n) {
            return !!this.expandedQuestions[n];
        }
    }
}
