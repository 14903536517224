declarationForm = function (urlUuid, urlUploadFile, urlIfns, urlOktmo, urlNotifyEmail, urlSend, urlSendStatus, urlResult) {
    return {
        id: null,
        error: null,
        recommendation: null,
        sendBtnLabel: 'Запустить создание декларации',
        sendBtnAvailable: true,
        isTurnstileReady: false,

        //init
        init() {
            global.onTurnstileReadyHandler(() => this.isTurnstileReady = true);
            fetch(urlUuid, {method: "POST"}).then(x => x.json()).then(x => this.id = x.uuid);
            this.notifyEmail = localStorage.getItem('notifyEmail') ?? '';
            if (this.notifyEmail) {
                this.isNotifyEmailSent = true;
            }
        },

        // file list
        fileList: [],
        fileListError: '',
        fileListAttention: '',
        fileListRecommendation: '',
        fileListLoading: false,
        addFile() {
            document.getElementById('fileListInput').click();
            this.fileListLoading = true;
        },
        afterFileUpload(e) {
            const fileEl = e.target.cloneNode(true);
            const afterLoad = (data) => {
                this.isTurnstileReady = false;
                global.onloadTurnstileCallback();
                this.fileListLoading = false;
                if (data) {
                    if (data.error) {
                        this.fileListError = data.error;
                        this.fileListRecommendation = data.recommendation;
                    } else {
                        this.error = null;
                        this.recommendation = null;
                        if (this.fileList.find(x => x.year === data.year)) {
                            this.fileListError = 'Отчет за ' + data.year + ' уже загружен ранее';
                            this.fileListRecommendation = 'Если вам все же нужно загрузить этот отчет, то сначала следует удалить загруженный';
                        } else {
                            this.resetFileListErrorAndAttention();
                            this.fileList.push({id: data.id, name: data.name, year: data.year});
                            this.updateSendLabel(1);
                            this.updateFileListAttention();
                            global.yandexGoal('file_added');
                        }
                    }
                }
                e.target.value = null;
            }
            if (fileEl.value) {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", urlUploadFile);
                xhr.onload = function(e){
                    let resp;
                    if (e.target.status === 413) {
                        resp = {error: 'Файл слишком большой', recommendation: 'Скорее всего вы загружаете не CSV-отчет, а чтото другое. Убедитесь, что у загружаемого файла расширение csv.'};
                    } else if (e.target.status !== 200) {
                        resp = {error: e.target.statusText};
                    } else {
                        resp = JSON.parse(e.target.response);
                    }
                    afterLoad(resp);
                };
                xhr.onerror = function(e){
                    afterLoad({error: e.target.statusText});
                };
                const form = document.createElement('form');
                const tokenEl = document.createElement('input');
                tokenEl.type = 'hidden';
                tokenEl.name = 'token';
                tokenEl.value = global.turnstileToken;
                form.appendChild(fileEl);
                form.appendChild(tokenEl);
                const formData = new FormData(form);
                xhr.setRequestHeader('x-declaration-id', this.id);
                xhr.send(formData);
            } else {
                afterLoad();
            }
        },
        removeFile(id) {
            this.fileList = this.fileList.filter(x => x.id !== id);
            this.resetFileListErrorAndAttention();
            this.updateSendLabel(-1);
            this.updateFileListAttention();
            this.error = null;
            this.recommendation = null;
        },
        resetFileListErrorAndAttention() {
            this.fileListError = null;
            this.fileListAttention = null;
            this.fileListRecommendation = null;
        },
        updateFileListAttention() {
            const yearList = this.fileList.map(x => x.year);
            const minYear = Math.min(...yearList);
            const maxYear = Math.max(...yearList);
            const minAllowYear = Math.max((new Date()).getFullYear() - 3, 2021);
            if (maxYear < minAllowYear) {
                this.fileListAttention = `Отчет за последний год слишком ранний`;
                this.fileListRecommendation = `Подача декларации возможна только за последние 3 года, то есть последний файл отчета должен быть за ${minAllowYear} или позже. Добавьте еще отчетов.`;
            } else if (this.fileList.length !== maxYear - minYear + 1) {
                const needYears = [];
                for (let i = minYear + 1; i < maxYear; i++) {
                    if (!yearList.includes(i)) {
                        needYears.push(i);
                    }
                }
                this.fileListAttention = `Вы еще не загрузили промежуточные отчеты`;
                this.fileListRecommendation = 'Нужно загрузить ' +
                    (needYears.length === 1 ? `отчет за ${needYears[0]} год` : `отчеты за ${needYears.map(x => x.toString()).join(', ')} года`);
            }
        },
        updateSendLabel(n) {
            const len = this.fileList.length;
            const minAllowYear = (new Date()).getFullYear() - 3;
            const yearList = this.fileList.map(x => x.year);
            const maxYear = Math.max(...yearList);
            if (!len) {
                this.sendBtnLabel = 'Запустить создание декларации';
                this.sendBtnAvailable = true;
            } else if (maxYear < minAllowYear) {
                this.sendBtnLabel = 'Запустить создание декларации';
                this.sendBtnAvailable = false;
            } else {
                const years = this.fileList.map(x => x.year);
                this.sendBtnLabel = `Запустить создание декларации за ${Math.max(...years)} год`;
                this.sendBtnAvailable = true;
            }
            if ((len === 0 && n === -1 ) || (len === 1 && n === 1)) {
                const classList = this.$refs.fileInfo.classList;
                classList.remove('shaking');
                this.$nextTick(() => {
                   setTimeout(() => classList.add('shaking'), 500);
                });
            }
        },

        // ndfl3
        hasXml: false,
        isNotFirst: false,
        isNotFirstError: '',
        correctionNumber: null,
        correctionNumberError: '',
        toggleHasXml() {
            this.hasXml = !this.hasXml;
            if (this.hasXml) {
                this.ifnsError = '';
                this.oktmoError = '';
                this.firstNameError = '';
                this.lastNameError = '';
                this.middleNameError = '';
                this.innError = '';
            }
        },
        onIsNotFirstClick() {},
        onCorrectionNumberKeyPress() {},

        // ifns & oktmo
        ifns: null,
        ifnsError: '',
        oktmo: null,
        oktmoError: '',
        ifnsList: [],
        oktmoList: [],
        changeIfns() {
            this.oktmo = null;
            this.selectOktmoReset();
            fetch(
                urlOktmo,
                {method: "POST", body: JSON.stringify({ifns: this.ifns})},
            ).then(x => x.json()).then(x => this.oktmoList = x);
        },

        // other
        firstName: '',
        firstNameError: '',
        lastName: '',
        lastNameError: '',
        middleName: '',
        middleNameError: '',
        inn: '',
        innError: '',
        publicOffer: false,
        publicOfferError: '',
        privacyPolicy: false,
        privacyPolicyError: '',
        onFirstNameKeyPress() { this.firstNameError = ''; },
        onLastNameKeyPress() { this.lastNameError = ''; },
        onMiddleNameKeyPress() { this.middleNameError = ''; },
        onInnKeyPress() { this.innError = ''; },
        onPublicOfferClick() { this.publicOfferError = ''; },
        onPrivacyPolicyClick() { this.privacyPolicyError = ''; },

        // notify email
        notifyEmail: '',
        isNotifyEmailSent: false,
        resetNotifyEmail() {
            this.isNotifyEmailSent = false;
        },
        sendNotifyEmail() {
            if (!this.notifyEmail) {
                return;
            }
            localStorage.setItem('notifyEmail', this.notifyEmail);
            fetch(urlNotifyEmail, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-declaration-id': this.id,
                },
                body: JSON.stringify({
                    token: global.turnstileToken,
                    email: this.notifyEmail,
                }),
            }).then(x => x.json()).then(x => {
                this.isTurnstileReady = false;
                global.onloadTurnstileCallback();
                if (x.error) {
                    //
                } else {
                    this.isNotifyEmailSent = true;
                }
            })
        },

        // send
        isSubmitting: false,
        generateText: generateTextList[0],
        generateTextIdx: 0,
        generateBlinker: false,
        generateTypingDelete: true,
        generateTimeoutList: [],
        send() {
            this.fileListError = this.fileList.length ? '' :
                'Загрузите хотябы один файл с отчетом от Interactive brokers';
            this.fileListRecommendation= this.fileList.length ? '' :
                'Без отчетов брокера никак не получится создать декларацию';
            if (this.hasXml) {
                this.ifnsError = this.ifns ? '' :
                    'Для XML-файла тербуется ваша ИФНС';
                this.oktmoError = this.oktmo ? '' :
                    'Для XML-файла тербуется ваш ОКТМО';
                this.firstNameError = this.firstName.length ? '' :
                    'Для XML-файла тербуется ваше имя';
                this.lastNameError = this.lastName.length ? '' :
                    'Для XML-файла тербуется ваша фамилия';
                this.middleNameError = this.middleName.length ? '' :
                    'Для XML-файла тербуется ваше отчество';
                this.innError = getInnError(this.inn);
            }
            this.publicOfferError = this.publicOffer ? '' :
                'Без согласия с офертой невозмжно создание декларации';
            this.privacyPolicyError = this.privacyPolicy ? '' :
                'Без согласия с обработкой персональных данных невозмжно создание декларации';

            if (
                this.ifnsError || this.oktmoError || this.fileListError || this.publicOfferError || this.privacyPolicyError ||
                (this.hasXml && (this.ifnsError || this.oktmoError || this.firstNameError || this.lastNameError || this.middleNameError || this.innError))
            ) {
                return;
            }

            this.submittingStart();
            this.error = null;
            this.recommendation = null;

            const clientUuid = (function() {
                const existId = localStorage.getItem('id');
                if (existId) {
                    return existId;
                }
                const newId = generateUUID();
                localStorage.setItem('id', newId);
                return newId;
            })()

            let data = {
                fileList: this.fileList.map(x => x.id),
                hasXml: this.hasXml,
                token: global.turnstileToken,
                notifyEmail: this.notifyEmail,
            };
            if (this.hasXml) {
                data = {
                    ...data,
                    ifns: this.ifns,
                    oktmo: this.oktmo,
                    lastName: this.lastName,
                    firstName: this.firstName,
                    middleName: this.middleName,
                    inn: this.inn,
                    correctionNumber: this.correctionNumber || 0,
                }
            }
            global.yandexGoal('send_declaration');
            fetch(urlSend, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-declaration-id': this.id,
                    'x-id': clientUuid,
                },
                body: JSON.stringify(data),
            }).then(x => x.json()).then(x => {
                this.isTurnstileReady = false;
                global.onloadTurnstileCallback();
                if (x.error) {
                    this.error = x.error;
                    this.recommendation = x.recommendation || null;
                    this.submittingFinish();
                } else {
                    this.waitStatus();
                }
            })
        },

        submittingStart() {
            this.isSubmitting = true;
            this.generationProgressStart();
        },
        submittingFinish() {
            this.isSubmitting = false;
            this.generateTimeoutList.forEach(t => clearTimeout(t));
            this.generateTimeoutList = [];
        },

        generationProgressStart() {
            this.generateTextIdx = 0;
            this.generateText = generateTextList[0];
            this.generateBlinker = false;
            this.generationProgressStartPrint();
        },
        generationProgressStartPrint() {
            if (!this.isSubmitting) {
                return;
            }
            this.generateTimeoutList.push(setTimeout(() => {
                this.generateBlinker = true;
                this.generateTextIdx++;
                this.generateTimeoutList.push(setTimeout(
                    () => this.generationProgressStartTyping(generateTextList[this.generateTextIdx]),
                    generateTimeoutBlink,
                ));
            }, generateTimeoutBetween));
        },
        generationProgressStartTyping(newText) {
            this.generateTypingDelete = true;
            this.generationProgressTyping(newText);
        },
        generationProgressTyping(newText) {
            if (!this.isSubmitting) {
                return;
            }
            this.generateTimeoutList.push(setTimeout(() => {
                if (this.generateTypingDelete) {
                    if (this.generateText === '') {
                        this.generateTypingDelete = false;
                    } else {
                        this.generateText = this.generateText.substring(0, this.generateText.length - 1);
                    }
                }
                if (!this.generateTypingDelete) {
                    if (this.generateText.length === newText.length) {
                        this.generationProgressFinish();
                        return;
                    }
                    this.generateText += newText.substring(this.generateText.length, this.generateText.length + 1);
                }
                this.generationProgressTyping(newText);
            }, generateTimeoutTyping()));
        },
        generationProgressFinish() {
            if (!this.isSubmitting) {
                return;
            }
            this.generateTimeoutList.push(setTimeout(() => {
                this.generateBlinker = false;
                if (this.generateTextIdx < generateTextList.length - 1) {
                    this.generationProgressStartPrint()
                }
            }, generateTimeoutBlink));
        },

        waitStatus(n) {
            n = n || 0
            let timeout;
            if (n === 0) {
                timeout = 3000;
            } else if (n < 5) {
                timeout = 1000;
            } else if (n < 10) {
                timeout = 3000;
            } else {
                timeout = 10000;
            }
            setTimeout(() => {
                this.waitStatusProcess().then(isCompleted => {
                    if (!isCompleted) {
                        this.waitStatus(n + 1);
                    }
                })
            }, timeout);
        },

        waitStatusProcess() {
            return fetch(urlSendStatus, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-declaration-id': this.id,
                },
            }).then(x => x.json()).then(x => {
                if (x.status === 'submitted') {
                    document.location.assign(urlResult.replace('99999999-9999-4999-9999-999999999999', this.id));
                    return true;
                }
                if (x.status === 'failed') {
                    this.error = x.message;
                    this.recommendation = x.recommendation;
                    this.isSubmitting = false;
                    return true;
                }
                return false;
            })
        },

        // select ifns
        selectIfnsOptions: [],
        selectIfnsOpen: false,
        selectIfnsSearch: '',
        selectIfnsIdx1: 0,
        selectIfnsIdx2: 0,
        selectIfnsValue: null,
        selectIfnsLabel: '',
        selectIfnsInit: function () {
            fetch(urlIfns, {method: "POST"}).then(x => x.json()).then(x => {
                this.ifnsList = x;
                this.selectIfnsOptions = x;
            });

            this.$watch('selectIfnsSearch', ((value) => {
                if (value) {
                    const valueLower = value.toLowerCase();
                    this.selectIfnsOptions = [];
                    this.ifnsList.forEach(item1 => {
                        const item2Selected = [];
                        item1.children.forEach(item2 => {
                            if (item2.value.indexOf(value) !== -1 || item2.label.toLowerCase().indexOf(valueLower) !== -1) {
                                item2Selected.push(item2);
                            }
                        })
                        if (item2Selected.length) {
                            this.selectIfnsOptions.push({
                                value: item1.value,
                                label: item1.label,
                                children: item2Selected,
                            });
                        }
                    })
                } else {
                    this.selectIfnsOptions = [...this.ifnsList];
                }
            }));
        },
        selectIfnsCloseListbox() {
            this.selectIfnsOpen = false;
            this.selectIfnsSearch = '';
        },
        selectIfnsToggleListbox() {
            if (this.selectIfnsOpen) {
                return this.selectIfnsCloseListbox();
            }

            this.ifnsError = '';
            this.selectIfnsOpen = true;
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.selectIfnsSearch.focus();
                    this.selectIfnsScroll();
                }, 50);
            })
        },
        selectIfnsSelectOption(idx1, idx2) {
            if (!this.selectIfnsOpen) {
                return this.selectIfnsToggleListbox();
            }
            const item2 = (() => {
                if (undefined !== idx1 && undefined !== idx2) {
                    return {...this.selectIfnsOptions[idx1].children[idx2]};
                } else {
                    return {...this.selectIfnsOptions[this.selectIfnsIdx1].children[this.selectIfnsIdx2]};
                }
            })();
            this.selectIfnsValue = item2.value;
            this.selectIfnsLabel = item2.label;
            this.selectIfnsCloseListbox();

            this.ifns = item2.value;
            this.changeIfns();
        },
        selectIfnsFocusPreviousOption() {
            let newIdx1 = this.selectIfnsIdx1;
            let newIdx2 = this.selectIfnsIdx2 - 1;
            if (newIdx2 < 0) {
                newIdx1--;
                if (newIdx1 < 0) {
                    return;
                }
                newIdx2 = this.selectIfnsOptions[newIdx1].children.length - 1;
            }
            this.selectIfnsIdx1 = newIdx1;
            this.selectIfnsIdx2 = newIdx2;
            this.selectIfnsScroll();
        },
        selectIfnsFocusNextOption() {
            const maxIdx1 = this.selectIfnsOptions.length - 1;
            const maxIdx2 = this.selectIfnsOptions[this.selectIfnsIdx1].children.length - 1;
            let newIdx1 = this.selectIfnsIdx1;
            let newIdx2 = this.selectIfnsIdx2 + 1;
            if (newIdx2 > maxIdx2) {
                newIdx1++;
                if (newIdx1 > maxIdx1) {
                    return
                }
                newIdx2 = 0;
            }
            this.selectIfnsIdx1 = newIdx1;
            this.selectIfnsIdx2 = newIdx2;
            this.selectIfnsScroll();
        },
        selectIfnsScroll() {
            const id = 'ifns-'+ this.selectIfnsIdx1 + '-' + this.selectIfnsIdx2;
            document.getElementById(id).scrollIntoView({
                block: "center",
            })
        },

        // select oktmo
        selectOktmoOpen: false,
        selectOktmoIdx: 0,
        selectOktmoValue: null,
        selectOktmoLabel: '',
        selectOktmoReset() {
            this.selectOktmoIdx = 0;
            this.selectOktmoValue = null;
            this.selectOktmoLabel = '';
        },
        selectOktmoCloseListbox() {
            this.selectOktmoOpen = false;
        },
        selectOktmoToggleListbox() {
            if (this.selectOktmoOpen) {
                return this.selectOktmoCloseListbox();
            }

            this.oktmoError = '';
            this.selectOktmoOpen = true;
        },
        selectOktmoSelectOption(idx) {
            if (!this.selectOktmoOpen) {
                return this.selectOktmoToggleListbox();
            }
            const item = this.oktmoList[idx];
            this.selectOktmoValue = item.value;
            this.selectOktmoLabel = item.label;
            this.selectOktmoCloseListbox();

            this.oktmo = item.value;
        },
        selectOktmoFocusPreviousOption() {
            let newIdx = this.selectOktmoIdx - 1;
            if (newIdx < 0) {
                return;
            }
            this.selectOktmoIdx = newIdx;
            this.selectOktmoScroll();
        },
        selectOktmoFocusNextOption() {
            const maxIdx = this.oktmoList.length - 1;
            let newIdx = this.selectOktmoIdx + 1;
            if (newIdx > maxIdx) {
                return;
            }
            this.selectOktmoIdx = newIdx;
            this.selectOktmoScroll();
        },
        selectOktmoScroll() {
            const id = 'oktmo-'+ this.selectOktmoIdx;
            document.getElementById(id).scrollIntoView({
                block: "center",
            })
        }
    }
}

function getInnError(value){
    if (!value.length) {
        return 'Для XML-файла тербуется ваш ИНН';
    }
    if (value.length !== 12) {
        return 'ИНН должен состоять из 12 цифр';
    }
    const a = value.split('');
    for (let symbol of a) {
        if (isNaN(Number(symbol))) {
            return 'ИНН может содержать только цифры';
        }
    }
    const checkSumOne = ((7 * a[0] + 2 * a[1] + 4 * a[2] + 10 * a[3] + 3 * a[4] + 5 * a[5] + 9 * a[6] + 4 * a[7] + 6 * a[8] + 8 * a[9]) % 11 ) % 10;
    const checkSumTwo = ((3 * a[0] + 7 * a[1] + 2 * a[2] + 4 * a[3] + 10 * a[4] + 3 * a[5] + 5 * a[6] + 9 * a[7] + 4 * a[8] + 6 * a[9] + 8 * a[10]) % 11 ) % 10;
    if (checkSumOne === Number(a[10]) && checkSumTwo === Number(a[11])) {
        return '';
    } else {
        return 'При вводе ИНН вы где-то допустили ошибку';
    }
}

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

const generateTimeoutBetween = 15000;
const generateTimeoutBlink = 2000;
const generateTimeoutTyping = () => Math.round(Math.random() * 150) + 100;
// задержка на фразе 15 + 2 блинка * 2 + 20 букв * 2 * .2 = 27 секунд
// на 7 фразах общее время 7 * 27 = 189 секунд, ~ 3 минут
const generateTextList = [
    'Подождите...',
    'Считываю данные отчета...',
    'Обработка операций...',
    'Рассчитываю налоги...',
    'Делаю пояснительную записку...',
    'Создаю пакет документов...',
    'Все еще делаю пакет документов...',
    'Осталось ещё немного...',
];
