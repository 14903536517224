tabsBlock = function () {
    return {
        active: 1,
        setActive(n) {
            this.active = n;
        },
        isActive(n) {
            return n === this.active;
        },
    }
}
