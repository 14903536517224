const turnstileReadyEvent = new Event("turnstileReady");
global.onTurnstileReadyHandler = function (callback) {
    global.addEventListener("turnstileReady", callback);
};
global.onloadTurnstileCallback = function () {
    turnstile.render('body', {
        sitekey: global.turnstileSiteKey,
        callback: function(token) {
            global.turnstileToken = token;
            global.dispatchEvent(turnstileReadyEvent);
            // console.log(`Challenge Success ${token}`);
        },
    });
};
