moneyMoveForm = function(urlSend) {
    return {
        urlSend,
        isSubmitting: false,
        isTurnstileReady: false,
        error: null,
        recommendation: null,
        result: null,

        init() {
            global.onTurnstileReadyHandler(() => this.isTurnstileReady = true);
        },

        setFile() {
            document.getElementById('fileInput').click();
        },
        afterFileUpload(e) {
            this.isSubmitting = true;
            const fileEl = e.target.cloneNode(true);
            const afterLoad = (data) => {
                this.isTurnstileReady = false;
                global.onloadTurnstileCallback();
                this.fileListLoading = false;
                this.isSubmitting = false;
                if (data) {
                    if (data.error) {
                        this.error = data.error;
                        this.recommendation = data.recommendation;
                    } else {
                        this.error = null;
                        this.recommendation = null;
                        this.result = data.html;
                        global.yandexGoal('money_move_file_added');
                    }
                }
                e.target.value = null;
            }
            if (fileEl.value) {
                const xhr = new XMLHttpRequest();
                xhr.open("POST", urlSend);
                xhr.onload = function(e){
                    let resp;
                    if (e.target.status === 413) {
                        resp = {error: 'Файл слишком большой', recommendation: 'Скорее всего вы загружаете не CSV-отчет, а чтото другое. Убедитесь, что у загружаемого файла расширение csv.'};
                    } else if (e.target.status !== 200) {
                        resp = {error: e.target.statusText};
                    } else {
                        resp = JSON.parse(e.target.response);
                    }
                    afterLoad(resp);
                };
                xhr.onerror = function(e){
                    afterLoad({error: e.target.statusText});
                };
                const form = document.createElement('form');
                const tokenEl = document.createElement('input');
                tokenEl.type = 'hidden';
                tokenEl.name = 'token';
                tokenEl.value = global.turnstileToken;
                form.appendChild(fileEl);
                form.appendChild(tokenEl);
                const formData = new FormData(form);
                xhr.send(formData);
            } else {
                afterLoad();
            }
        },
        closeResult() {
          this.result = null;
        },
    }
}
